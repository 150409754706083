/*
 * Code for modals.
 *
 * Include this pack on pages that include modals.
 * Only one modal should be open at a time ('.modal.show').
 */


// Initialization of modals
const $modals = $('.modal');
if ($modals.length) {
  // Set accessibility
  $modals.attr('role', 'dialog');
  $modals.attr('tabindex', '-1');

  // Set up close icon button
  $('.modal__header')
    .append("<button type='button' class='close js-modal-close-trigger' data-dismiss='modal' aria-label='Close'>✕</button>");
}

// Initialization of open modal
const $open_modal = $('.modal.show');
if ($open_modal.length) {
  // Prevent scroll on body
  document.querySelector('body').classList.add('modal-open');

  containFocus();

  // Close modal when clicking outside of modal dialog
  $('.modal__overlay').addClass('js-modal-close-trigger');

  // Close modal when clicked
  $('.js-modal-close-trigger').on('click', event => hideModal(event));

  // Close the modal on pressing ESC
  document.addEventListener('keydown', (event) => {
    if ($('.modal.show').length && (event.key === 'Escape' || event.key === 'Esc')) {
      event.preventDefault();
      hideModal(event);
    }
  });

  $open_modal.focus({ preventScroll: true });
}

// Contain focus in modal dialog
function containFocus() {
  $(document)
    .off('focusin')
    .on('focusin', (event) => {
      if (document !== event.target &&
        document.querySelector('.modal.show') !== event.target &&
        $(document.querySelector('.modal.show')).has(event.target).length === 0) {
        document.querySelector('.modal.show').focus({ preventScroll: true });
      }
    });
}


// Hide the modal
function hideModal(event) {
  // prevent bubbling for click events
  if (event.type === 'click' && event.target !== event.currentTarget) return;

  const $modal = $('.modal.show');

  // enable scroll on body
  document.querySelector('body').classList.remove('modal-open');

  // pause any videos in the modal
  $modal.find('video').each((key, video) => video.pause());

  $(document).off('focusin');

  $modal.removeClass('show');
  $modal.css('display', 'none');
  $modal.attr('aria-hidden', 'true');
}


// Mark popup as seen, for users who click on "Don't show me again"
$('.js-modal-popup-viewed').on('click', (event) => {
  event.preventDefault();

  $.ajax({
    url: event.target.dataset.url,
    type: 'PUT',
    error: () => console.log('Error: Popup was not marked as seen.')
  });
});
